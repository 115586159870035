<template>
  <div class="home">
      <header>
        <b-navbar toggleable="lg" style="background: #f7efdf" >
        <b-container>
        <b-navbar-brand href="#"><img style="width: 200px" src="@/assets/logoyo.png"/></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">1137 Jászai Mari tér 4/a</b-nav-item>
            
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          
        </b-collapse>
        </b-container>
      </b-navbar>
      </header>
      <section class="p-5">
        <b-container class="text-center">
          <h1>Megnyitottunk</h1>
          <p> Szeretettel várjuk kedves Vendégeinket Magyarország első müzlibárjában a Jászai mari téren.</p>
          <h3>Nyitva: minden nap 8.00 - 20.00</h3>
        </b-container>
      </section>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.7843213012916!2d19.046427915927314!3d47.51359137917868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc0f3fe9e4fd%3A0xd8183c89e33866a9!2sBudapest%2C%20J%C3%A1szai%20Mari%20t%C3%A9r%204a%2C%201137!5e0!3m2!1shu!2shu!4v1654153179942!5m2!1shu!2shu" style="width: 100%;border:0; margin: 0px" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  <section style="background:#c73f49; color: white;" >
    <b-container class="p-3">
      <b>25 év alatti, angolul jól beszélő pultosokat, pincéreket keresünk!Várjuk önéletrajzodat! <a style="color: white" href="mailto: muzlibar22@gmail.com">muzlibar22@gmail.com</a></b>
    </b-container>
  </section>
  <section  style="background: #f7efdf" class="p-5">
    <b-container>
        <h2 class="text-center"> Amit nálunk megkóstolhatsz</h2>
        <b-row class="">
          <b-col>
            <b-card
              title="Müzli koktélok"
              :img-src="require('@/assets/prod1.jpg')"
              img-alt="Image"
              img-top
              tag="article"
              
              class="m-5 text-center"
            >


            </b-card>
          </b-col>
          <b-col>
            <b-card
              title="Kávé különlegességek"
              :img-src="require('@/assets/prod2.png')"
              img-alt="Image"
              img-top
              tag="article"
              class="m-5 text-center"
            >
             

             
            </b-card>
          </b-col>
        </b-row>
    </b-container>
  </section>
  <section style="background:#c73f49; color: white;" >
    <b-container class="p-3">
      <b>MÜZLIBÁR 2022 KFT.</b><br>1134 Budapest, Szabolcs u. 26-28 B. ép. 4/517<br>Adószám: 27805420-2-41<br>Cégjegyzékszám: 01-09-398739<br>Szlsz.: OTP 11705022-21459605<br><a style="color: white" href="mailto: muzlibar22@gmail.com">muzlibar22@gmail.com</a>
    </b-container>
  </section>


  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
